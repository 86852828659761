import { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import commaNumber from 'comma-number';
import * as XLSX from 'xlsx';
import { useParams } from "react-router-dom";

const ByCustomer = ({ icon }) => {
    const customerId = useParams().id

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "CarServices");
        XLSX.writeFile(wb, "CarServices.xlsx");
    };


    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/appointment_details/${customerId}`);
        setData(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable({
                    responsive: true,
                });
            }
        }
    }, [data]);

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Service By Customer</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Service By Customer</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-success ms-2" onClick={exportToExcel}>
                                        <span><icon.FileText /> Export to Excel</span>
                                    </button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Service</th>
                                            <th>Item</th>
                                            <th>Part Number</th>
                                            <th>UoM</th>
                                            <th>Cost</th>
                                            <th>Total Cost</th>
                                            <th>Note</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.appointment_details_id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.service_name}</td>
                                                    <td>{item.item_name}</td>
                                                    <td>{item.part_number}</td>
                                                    <td>{item.uom}</td>
                                                    <td>{commaNumber(item.cost)} ع.د</td>
                                                    <td>{commaNumber(item.total_cost)} ع.د</td>
                                                    <td>{item.note ? item.note : '-'}</td>
                                                    <td>{new Date(item.created_at).toLocaleDateString()}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ByCustomer;