import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import * as icon from 'react-feather';
import ErrorPage from './Error';
import APP from '../App';
import Login from './Login';
import Users from './Pages/Users';
import Dashboard from './Pages/Dashboard';
import Customers from './Pages/Customers';
import Cars from './Pages/Cars';
import Services from './Pages/Services';
import Appointments from './Pages/Appointments';
import ByCustomer from './Pages/ByCustomer';
import NewAppointments from './Pages/NewAppointment';
import EditAppointments from './Pages/EditAppointment';
import Makes from './Pages/Makes';
import Models from './Pages/Models';
import Items from './Pages/Items';
import ModelMap from './Pages/ModelMap';
import DealerCodeI from './Pages/DealerCodeI';
import DealerCodeH from './Pages/DealerCodeH';

const Routes = createBrowserRouter([
    {
        path: "/login", element:
            <div className="main-wrapper">
                <div className="page-wrapper full-page">
                    <Login icon={icon} />
                </div>
                .0
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/",
        element: <APP><Dashboard icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/users",
        element: <APP><Users icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/customers",
        element: <APP><Customers icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/makes",
        element: <APP><Makes icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/models",
        element: <APP><Models icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/cars",
        element: <APP><Cars icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/items",
        element: <APP><Items icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/services",
        element: <APP><Services icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/appointments",
        element: <APP><Appointments icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/new-appointment/:id",
        element: <APP><NewAppointments icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/edit-appointment/:id",
        element: <APP><EditAppointments icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/by-customer/:id",
        element: <APP><ByCustomer icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report-model",
        element: <APP><ModelMap icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report-i",
        element: <APP><DealerCodeI icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/report-h",
        element: <APP><DealerCodeH icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },




]);

export default Routes;