import React, { useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import $ from "jquery";

const Sidebar = ({ icon }) => {
    const navigate = useNavigate();
    useEffect(() => {
        axios.get("/auth").then((res) => {
            if (!res.data) {
                navigate('/login')
            }
        });
    }, []);

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <Link to="/" className="sidebar-brand">
                    Car<span>Service</span>
                </Link>
                <div className="sidebar-toggler not-active" onClick={() => {
                    $('.sidebar-header .sidebar-toggler').toggleClass('active not-active');
                    if (window.matchMedia('(min-width: 992px)').matches) {
                        $('body').toggleClass('sidebar-folded');
                    } else if (window.matchMedia('(max-width: 991px)').matches) {
                        $('body').toggleClass('sidebar-open');
                    }
                    $(".sidebar .sidebar-body").on('hover', () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').addClass("open-sidebar-folded");
                        }
                    }, () => {
                        if ($('body').hasClass('sidebar-folded')) {
                            $('body').removeClass("open-sidebar-folded");
                        }
                    });
                }}>
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div className="sidebar-body">
                <ul className="nav">
                    <li className="nav-item nav-category">Main</li>
                    <li className="nav-item">
                        <Link to="/users" className="nav-link">
                            <icon.User className="link-icon" />
                            <span className="link-title">Users</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/customers" className="nav-link">
                            <icon.Users className="link-icon" />
                            <span className="link-title">Customers</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/makes" className="nav-link">
                            <icon.Flag className="link-icon" />
                            <span className="link-title">Make</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/models" className="nav-link">
                            <icon.Clipboard className="link-icon" />
                            <span className="link-title">Models</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/cars" className="nav-link">
                            <icon.Truck className="link-icon" />
                            <span className="link-title">Cars</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/items" className="nav-link">
                            <icon.Layers className="link-icon" />
                            <span className="link-title">Items</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/services" className="nav-link">
                            <icon.Settings className="link-icon" />
                            <span className="link-title">Services</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/appointments" className="nav-link">
                            <icon.Clock className="link-icon" />
                            <span className="link-title">Appointments</span>
                        </Link>
                    </li>
                    <li className="nav-item nav-category">Reports</li>
                    <li className="nav-item">
                        <Link to="/report-model" className="nav-link">
                            <icon.FileText className="link-icon" />
                            <span className="link-title">Model Mapping</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/report-i" className="nav-link">
                            <icon.FileText className="link-icon" />
                            <span className="link-title">Dealer Code I</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/report-h" className="nav-link">
                            <icon.FileText className="link-icon" />
                            <span className="link-title">Dealer Code H</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Sidebar;