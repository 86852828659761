import { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import Select from 'react-select'
import * as XLSX from 'xlsx';

const ModelMap = ({ icon }) => {

    const exportToExcel = () => {
        let exportData = [[selected]];

        data.forEach(item => {
            exportData.push([item.model_name]);
        });
        const ws = XLSX.utils.aoa_to_sheet(exportData);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "ModelMapping");
        XLSX.writeFile(wb, "ModelMapping.xlsx");
    };

    const exportToCSV = () => {
        let exportData = [[selected]];

        data.forEach(item => {
            exportData.push([item.model_name]);
        });
        const ws = XLSX.utils.aoa_to_sheet(exportData);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "ModelMapping");
        XLSX.writeFile(wb, "ModelMapping.csv");
    };


    const [data, setData] = useState([]);
    const [make, setMake] = useState([]);
    const [selected, setSelected] = useState('all')
    const getData = async () => {
        const { data } = await axios.get('/model');
        setData(data);
        const { data: make } = await axios.get('/make');
        setMake(make.map((item) => { return { value: item.id, label: item.make_name }; }))
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable({
                    responsive: true,
                });
            }
        }
    }, [data]);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Model Report</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Model Mapping</h6>
                                    <Select options={make} placeholder="Filter By Make" onChange={(e) => {
                                        axios.get(`/model/${e.value}`).then((res) => {
                                            setData(res.data)
                                            setSelected(e.label)
                                        })
                                    }
                                    } />
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-success ms-2" onClick={exportToExcel}>
                                        <span className="ms-2"><icon.FileText /> Export to Excel</span>
                                    </button>
                                    <button className="btn btn-sm btn-dark ms-2" onClick={exportToCSV}>
                                        <span className="ms-2"><icon.FileText /> Export to CSV</span>
                                    </button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>{selected}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.model_name}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ModelMap;