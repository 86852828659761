import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from 'react-select'
import commaNumber from 'comma-number';
import { Link, useParams } from "react-router-dom";

const NewAppointments = ({ icon }) => {
    const appointmentId = useParams().id
    const [data, setData] = useState([]);
    const [items, setItems] = useState([]);
    const getData = async () => {
        const { data } = await axios.get(`/appointment_details/${appointmentId}`);
        setData(data)
        const { data: item } = await axios.get('/item');
        setItems(item.map((item) => {
            return {
                value: item.id,
                label: `${item.item_name} - ${item.cost} ع.د`,
                cost: item.cost
            };
        }));
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    useEffect(() => { }, [data])


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">New Appointment</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <h6 className="card-title">New Appointment Service</h6>
                                </div>
                                <div className="col-md-6">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        const formData = new FormData(e.target);
                                        const data = Object.fromEntries(formData);
                                        axios.post(`/appointment_details`, data).then((res) => {
                                            toast.success('Added Successfully');
                                            getData();
                                        })
                                    }} >
                                        <input type="hidden" name="appointment_id" defaultValue={appointmentId} required />
                                        <div className="mb-3">
                                            <label className="form-label">Items</label>
                                            <Select options={items} name="item_id" placeholder="Select an item" required onChange={(e) => {
                                                $('#cost').val(e.cost);
                                            }} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Cost</label>
                                            <input type="number" step="any" className="form-control" id="cost" name="cost" placeholder="0" required />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Qty</label>
                                            <input type="number" step="any" className="form-control" name="qty" placeholder="0" defaultValue={1} required />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Note</label>
                                            <textarea type="text" className="form-control" name="note" placeholder="Note" />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Add Service <icon.ArrowDown size="18" /></button>
                                    </form>
                                </div>
                                <div className="col-md-12 mt-4">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Service</th>
                                                    <th>Item</th>
                                                    <th>Part Number</th>
                                                    <th>UoM</th>
                                                    <th>Cost</th>
                                                    <th>Qty</th>
                                                    <th>Total Cost</th>
                                                    <th>Note</th>
                                                    <th>option</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => {
                                                    return (
                                                        <tr key={item.appointment_details_id}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.service_name}</td>
                                                            <td>{item.item_name}</td>
                                                            <td>{item.part_number}</td>
                                                            <td>{item.uom}</td>
                                                            <td>{commaNumber(item.cost)} ع.د</td>
                                                            <td>{item.qty}</td>
                                                            <td>{commaNumber(item.total_cost)} ع.د</td>
                                                            <td>{item.note ? item.note : '-'}</td>
                                                            <td>
                                                                <span type="button" className="badge bg-danger mx-1"
                                                                    onClick={() => {
                                                                        Swal.fire({
                                                                            title: 'Are you sure?',
                                                                            text: "You won't be able to revert this!",
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: '#3085d6',
                                                                            cancelButtonColor: '#d33',
                                                                            confirmButtonText: "Yes, delete it!",
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                axios.delete(`/appointment_details/${item.appointment_details_id}`).then(() => {
                                                                                    getData();
                                                                                })
                                                                            }
                                                                        })
                                                                    }}>
                                                                    <icon.Trash size="18" />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Link to="/appointments" type="button" className="btn btn-success mt-2" >Save Changes <icon.Save size="18" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default NewAppointments;