import axios from "axios";
import { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';

const Dashboard = () => {
    const [stats, setStats] = useState([]);

    const getData = async () => {
        const { data } = await axios.get('/stats');
        setStats(data);
    };

    useEffect(() => {
        getData();
    }, []);

    const serviceCars = stats.map(stats => `${stats.make_name} - ${stats.model_name} - ${stats.plate_number}`);
    const serviceData = {
        series: [{
            name: 'Service Car',
            data: stats.map(stats => stats.total_cost),
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: stats.map(stats => new Date(stats.appointment_date).toLocaleDateString()),
            },
            tooltip: {
                y: {
                    formatter: function (val, { seriesIndex, dataPointIndex }) {
                        const serviceCar = serviceCars[dataPointIndex];
                        return `${val} ع.د - ${serviceCar}`;
                    }
                }
            },
        },
    };

    return (
        <div className="page-content">
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-baseline mb-2">
                                <h6 className="card-title mb-0">Service Car Summary </h6>
                            </div>
                            <p className="text-muted">Service Car Summary encompasses activities related to the maintenance, repair, and overall servicing of vehicles to ensure optimal performance and reliability.</p>
                            <div id="chart">
                                <ReactApexChart options={serviceData.options} series={serviceData.series} type="bar" height={350} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;