import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from 'react-select'
import * as XLSX from 'xlsx';
import { Link, useNavigate } from "react-router-dom";
import commaNumber from "comma-number";

const Appointments = ({ icon }) => {
    let navigate = useNavigate();

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "CarServices");
        XLSX.writeFile(wb, "CarServices.xlsx");
    };

    const exportToCSV = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "CarServices");
        const csvContent = XLSX.write(wb, { bookType: "csv", type: "string" });
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "CarServices.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const [data, setData] = useState([]);
    const [cars, setCars] = useState([]);
    const [customer, setCustomer] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/appointment');
        setData(data);
        const { data: car } = await axios.get('/car');
        setCars(car.map((item) => {
            return {
                value: item.car_id,
                label: `${item.make_name}- ${item.model_name} - ${item.plate_number}`,
            };
        }));
        const { data: customer } = await axios.get('/customer');
        setCustomer(customer.map((item) => {
            return {
                value: item.customer_id,
                label: item.full_name,
            };
        }));
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable({
                    responsive: true,
                });
            }
        }
    }, [data]);

    const [status, setStatus] = useState('scheduled');
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Making Appointments</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Service Appointments</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-warning mx-2" data-bs-toggle="modal" data-bs-target="#appointment">
                                        <icon.Settings />
                                        <span className="ms-2">New Appointment</span>
                                    </button>
                                    <button className="btn btn-sm btn-success ms-2" onClick={exportToExcel}>
                                        <span><icon.FileText /> Export to Excel</span>
                                    </button>
                                    <button className="btn btn-sm btn-dark ms-2" onClick={exportToCSV}>
                                        <span><icon.FileText /> Export to CSV</span>
                                    </button>
                                    <div className="modal fade" id="appointment" tabIndex={-1} aria-labelledby="appointment" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">New Appointments</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/appointment`, data).then((res) => {
                                                        window.$(`#appointment`).modal('hide');
                                                        if (res.data) {
                                                            navigate(`/new-appointment/${res.data}`)
                                                        } else {
                                                            toast.error('Something Wrong Please Try Again !');
                                                        }
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">Customers</label>
                                                            <Select options={customer} name="customer_id" placeholder="Select Customer" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Car</label>
                                                            <Select options={cars} name="car_id" placeholder="Select Car" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Status</label>
                                                            <select className="form-select" name="status">
                                                                <option>scheduled</option>
                                                                <option>completed</option>
                                                                <option>cancelled</option>
                                                                <option>paid</option>
                                                            </select>
                                                        </div>
                                                        <div className="mb-3" id="newStatus">
                                                            <label className="form-label">Biling Date</label>
                                                            <input type="date" className="form-control" name="billing_date" />
                                                            <div id="billingHelp" class="form-text">Leav it if Status Not <span className="text-primary">PAID</span>.</div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Appointment Date</label>
                                                            <input type="date" className="form-control" name="appointment_date" required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Next</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Customer</th>
                                            <th>Phone No.</th>
                                            <th>Make</th>
                                            <th>Model</th>
                                            <th>Year</th>
                                            <th>Plate No.</th>
                                            <th>VIN</th>
                                            <th>Mileage (KM)</th>
                                            <th>Cost</th>
                                            <th>Status</th>
                                            <th>Billing_date</th>
                                            <th>Action By</th>
                                            <th>Appointment Date</th>
                                            <th>option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.appointment_id}>
                                                    <td>{index + 1}</td>
                                                    <td><Link to={`/by-customer/${item.customer_id}`}>{item.customer_name}</Link></td>
                                                    <td>{item.phone_number}</td>
                                                    <td>{item.make_name}</td>
                                                    <td>{item.model_name}</td>
                                                    <td>{item.year}</td>
                                                    <td>{item.plate_number}</td>
                                                    <td>{item.vin}</td>
                                                    <td>{item.mileage_km}</td>
                                                    <td>{commaNumber(item.total_cost)}</td>
                                                    <td>{item.status === "scheduled" ? <span className="badge bg-warning text-dark">Scheduled</span> : item.status === "completed" ? <span className="badge bg-primary">Completed</span> : item.status === "paid" ? <span className="badge bg-success">Paid</span> : <span className="badge bg-danger">Cancelled</span>}</td>
                                                    <td>{item.billing_date && !isNaN(new Date(item.billing_date).getTime()) ? new Date(item.billing_date).toLocaleDateString() : '-'}</td>
                                                    <td>{item.full_name}</td>
                                                    <td>{new Date(item.appointment_date).toLocaleDateString()}</td>
                                                    <td>
                                                        <span type="button" className="badge bg-success mx-1" data-bs-toggle="modal" data-bs-target={`#status${item.appointment_id}`} onClick={() => setStatus(item.status)}>
                                                            <icon.CreditCard size="18" />
                                                        </span>
                                                        <Link to={`/edit-appointment/${item.appointment_id}`} className="badge bg-warning text-dark mx-1">
                                                            <icon.Edit size="18" />
                                                        </Link>
                                                        <span type="button" className="badge bg-danger mx-1"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: 'Are you sure?',
                                                                    text: "You won't be able to revert this!",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: "Yes, delete it!",
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        axios.delete(`/appointment/${item.appointment_id}`).then(() => {
                                                                            getData();
                                                                        })
                                                                    }
                                                                })
                                                            }}>
                                                            <icon.Trash size="18" />
                                                        </span>
                                                    </td>
                                                    <div className="modal fade" id={`status${item.appointment_id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title">New Car</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                </div>
                                                                <form onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    const formData = new FormData(e.target);
                                                                    const data = Object.fromEntries(formData);
                                                                    axios.put(`/appointment/${item.appointment_id}`, data).then((res) => {
                                                                        if (res.data !== 0) {
                                                                            window.$(`#status${item.appointment_id}`).modal('hide');
                                                                            toast.success('Upadted Successfully');
                                                                            getData();
                                                                        } else {
                                                                            toast.warning('please select a Date')
                                                                        }

                                                                    })
                                                                }} >
                                                                    <div className="modal-body">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Status</label>
                                                                            <select className="form-select" name="status" value={status} onChange={handleStatusChange}>
                                                                                <option>scheduled</option>
                                                                                <option>completed</option>
                                                                                <option>cancelled</option>
                                                                                <option>paid</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="mb-3" id="newStatus">
                                                                            <label className="form-label">Biling Date</label>
                                                                            <input type="date" className="form-control" name="billing_date" defaultValue={item.billing_date ? item.billing_date.split('T')[0] : ''} />
                                                                            <div id="billingHelp" class="form-text">Leav it if Status Not <span className="text-primary">PAID</span>.</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Appointments;