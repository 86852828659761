import { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import * as XLSX from 'xlsx';

const DealerCodeI = ({ icon }) => {
    let exportData = [['Billing No.', 'Item Type', 'Part No.', 'Description', 'Quantity', 'UoM']];

    const exportToExcel = () => {
        data.forEach(item => {
            exportData.push([item.appointment_id, item.item_name, item.part_number, item.description, item.qty, item.uom]);
        });
        const ws = XLSX.utils.aoa_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "DealerCode_I");
        XLSX.writeFile(wb, "DealerCode_I.xlsx");
    };


    const exportToCSV = () => {
        data.forEach(item => {
            exportData.push([item.appointment_id, item.item_name, item.part_number, item.description, item.qty, item.uom]);
        });
        const ws = XLSX.utils.aoa_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "DealerCode_I");
        XLSX.writeFile(wb, "DealerCode_I.csv");
    };


    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/appointment_details');
        setData(data);
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable({
                    responsive: true,
                });
            }
        }
    }, [data]);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Dealer Code I Report</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Dealer Code I</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-success ms-2" onClick={exportToExcel}>
                                        <span className="ms-2"><icon.FileText /> Export to Excel</span>
                                    </button>
                                    <button className="btn btn-sm btn-dark ms-2" onClick={exportToCSV}>
                                        <span className="ms-2"><icon.FileText /> Export to CSV</span>
                                    </button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Billing No.</th>
                                            <th>Item Type</th>
                                            <th>Part No.</th>
                                            <th>Description</th>
                                            <th>Quantity</th>
                                            <th>UoM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.appointment_id}</td>
                                                    <td>{item.item_name}</td>
                                                    <td>{item.part_number}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.qty}</td>
                                                    <td>{item.uom}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default DealerCodeI;