import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Select from 'react-select'
import commaNumber from "comma-number";

const Items = ({ icon }) => {

    const [data, setData] = useState([]);
    const [service, setService] = useState([])
    const getData = async () => {
        const { data } = await axios.get('/item');
        setData(data);
        const { data: service } = await axios.get('/service');
        setService(service.map((item) => {
            return {
                value: item.service_id,
                label: item.service_name,
                cost: item.cost
            };
        }));
    };

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable({
                    responsive: true,
                });
            }
        }
    }, [data]);

    const [currentService, setCurrentService] = useState([])

    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Items</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Item List</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#new">
                                        <icon.Plus />
                                        <span className="ms-2">New Item</span>
                                    </button>
                                    <div className="modal fade" id="new" tabIndex={-1} aria-labelledby="new" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">New Item</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                </div>
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    const formData = new FormData(e.target);
                                                    const data = Object.fromEntries(formData);
                                                    axios.post(`/item`, data).then(() => {
                                                        window.$(`#new`).modal('hide');
                                                        toast.success('Added Successfully');
                                                        getData();
                                                    })
                                                }} >
                                                    <div className="modal-body">
                                                        <div className="mb-3">
                                                            <label className="form-label">Service</label>
                                                            <Select options={service} name="service_id" placeholder="Select Service" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Item Name</label>
                                                            <input type="text" className="form-control" name="item_name" placeholder="Item Name" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Part Number</label>
                                                            <input type="text" className="form-control" name="part_number" placeholder="Part Number" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">UoM</label>
                                                            <input type="text" className="form-control" name="uom" placeholder="UoM" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Cost</label>
                                                            <input type="number" className="form-control" name="cost" placeholder="0.00" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Description</label>
                                                            <textarea className="form-control" name="description" placeholder="Description" required />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Service</th>
                                            <th>Item</th>
                                            <th>Part Number</th>
                                            <th>UoM</th>
                                            <th>Cost</th>
                                            <th>Description</th>
                                            <th>option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.service_name}</td>
                                                    <td>{item.item_name}</td>
                                                    <td>{item.part_number}</td>
                                                    <td>{item.uom}</td>
                                                    <td>{commaNumber(item.cost)} ع.د</td>
                                                    <td>{item.description}</td>
                                                    <td>
                                                        <span type="button" className="badge bg-warning text-dark  mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`} onClick={() => {
                                                            setCurrentService({ value: item.service_id, label: item.service_name })
                                                        }}>
                                                            <icon.Edit size="18" />
                                                        </span>
                                                        <span type="button" className="badge bg-danger mx-1"
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: 'Are you sure?',
                                                                    text: "You won't be able to revert this!",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: "Yes, delete it!",
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        axios.delete(`/item/${item.id}`).then(() => {
                                                                            getData();
                                                                        })
                                                                    }
                                                                })
                                                            }}>
                                                            <icon.Trash size="18" />
                                                        </span>
                                                        <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="editcategory">Edit</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                    </div>
                                                                    <form onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        const formData = new FormData(e.target);
                                                                        const data = Object.fromEntries(formData);
                                                                        axios.put(`/item/${item.id}`, data).then(() => {
                                                                            window.$(`#edit${item.id}`).modal('hide');
                                                                            toast.success('Updated Successfully');
                                                                            getData();
                                                                        })
                                                                    }} >
                                                                        <div className="modal-body">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Service</label>
                                                                                <Select options={service} name="service_id" placeholder="Select Service" value={currentService} onChange={(e) => {
                                                                                    setCurrentService({ value: e.value, label: e.label });
                                                                                }} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Item Name</label>
                                                                                <input type="text" className="form-control" name="item_name" placeholder="Item Name" defaultValue={item.item_name} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Part Number</label>
                                                                                <input type="text" className="form-control" name="part_number" placeholder="Part Number" defaultValue={item.part_number} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">UoM</label>
                                                                                <input type="text" className="form-control" name="uom" placeholder="UoM" defaultValue={item.uom} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Cost</label>
                                                                                <input type="number" className="form-control" name="cost" placeholder="0.00" defaultValue={item.cost} required />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Description</label>
                                                                                <textarea className="form-control" name="description" placeholder="Description" defaultValue={item.description} required />
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                            <button type="submit" className="btn btn-primary">Update</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Items;